.employee-overview {
  font-size: 16px;
}
.employee-info {
  padding: 10px;
  text-align: center;
}
.employee-info .employee-technology {
  font-weight: 400;
  padding-left: 2px;
}
.employee-notes  .p-paginator {
  background: #fefbc4;
  border-color: transparent;
  color: #276749;
  

}
.employee-notes .p-paginator .p-paginator-pages .p-paginator-page {
  background-color: unset;
  color: black;

  
}
.employee-notes .p-paginator .p-paginator-first,
.employee-notes.p-paginator .p-paginator-prev,
.employee-notes.p-paginator .p-paginator-next,
.employee-notes .p-paginator .p-paginator-last,
.employee-notes  .p-paginator-pages {
  background-color: wheat;
 
}
.employee-notes  .p-highlight {
  background-color: #276749;
  color: black;
}
.employee-notes  .p-paginator-page-end {
  background-color: transparent;
  color: black;
}
.employee-notes  .p-paginator-element {
  background-color: transparent;
  color: black;
}
.employee-notes .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.employee-notes .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.employee-notes .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.employee-notes .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgb(222, 201, 163);
  border-color: transparent;
  color: black;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 2.286em;
  height: 2.286em;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 2.286em;
  height: 2.286em;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}
.employee-notes .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgb(254, 255, 233);
  border-color:rgb(254, 255, 233);
 
  color: black;
}
.employee-notes .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
  color: black;
}
  

.employee-notes form {
  width:100%;
}
::-webkit-scrollbar {
  width:4px;
 

}
::-webkit-scrollbar-track {
  background: #EDF2F7;
  border-radius: 100vh;
  margin-block: 0.5px;
}
::-webkit-scrollbar-thumb {
  background-color: hsl(39, 21%, 52%);
  border-radius: 100vh;
  border-color: 0.1px solid #EDF2F7;;
}
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(39, 23%, 62%)
}
::-webkit-scrollbar-thumb:active {
  background-color: hsl(39, 23%, 62%)
}
@supports(scrollbar-color: red blue;){
  * {
    scrollbar-color:  hsl(39, 21%, 52%) #EDF2F7;
    scrollbar-width: auto;
  }
}